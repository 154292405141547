$bp-small: 768px;

$main-color: #192380;
$main-color-dark-1: #0d1669;
$main-color-dark-2: #060d4c;
$main-color-light-1: #2b369d;
$main-color-light-2: #4851a6;
$background-color: #ebebeb;
$white: #fdfdfd;
$black: #020202;

body {
  margin: 0;
  font-size: 10pt;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 5px 0;
  color: dimgray;
  font-weight: 400;
  font-size: 2.5em;
}

h2 {
  margin: 5px 0;
  color: darkgray;
  font-weight: 600;
  font-size: 1.2em;
}

p {
  margin: 15px 0 5px;
}

.App {
  background-color: $background-color;
  color: $black;
  min-height: 100vh;
  min-width: 100%;
}

.LoadingCircle {
  width: 100px;
  height: 100px;
  margin: 25vh auto;

  @keyframes svg-animation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes circle-animation {
    0%,
    10% {
      stroke-dashoffset: 270;
      transform: rotate(0);
    }
    50%,
    60% {
      stroke-dashoffset: 75;
      transform: rotate(45deg);
    }
    100% {
      stroke-dashoffset: 270;
      transform: rotate(360deg);
    }
  }

  svg {
    animation: 2s linear infinite svg-animation;
  }

  circle {
    animation: 1.6s ease-in-out infinite both circle-animation;
    fill: transparent;
    stroke: $white;
    stroke-width: 10%;
    stroke-dasharray: 283;
    stroke-linecap: round;
    transform-origin: 50% 50%;
    stroke-dashoffset: 50;
  }
}

.Card {
  margin: 10px;
  padding: 5px;
  width: auto;
  height: auto;
  background-color: $white;
  box-shadow: 0 0 4px 0 lightgray;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &__Text {
    padding: 5px;
  }
}

.Header {
  height: 120px;
  background-color: $main-color;
  box-shadow: 0 0 4px 0 gray;
}

.Footer {
  text-align: center;
  color: darkgray;
  padding-bottom: 10px;

  span {
    display: block;
    padding: 3px;
  }
}

.Main {
  max-width: $bp-small;
  margin: -100px auto 0;
}
